
// import '@fortawesome/fontawesome-free/css/all.min.css'; 
//import 'bootstrap-css-only/css/bootstrap.min.css'; 

// import 'mdbreact/dist/css/mdb.css';
//import './src/styles/global.css'


import "tachyons"
import "./src/styles/main.scss"
